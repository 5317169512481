import React, { useCallback } from 'react';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import { CustomSelect } from '@src/components/Search/Navigate/Select';
import { ManualSelect } from '@src/components/Search/Navigate/Select';
import { formatQualification, removeHyphens } from '@src/utils/utils';
import { useIsMdMax } from '@src/hooks/useBreakpoints';
import AreaInfoModal from '../AreaInfoModal';
import * as styles from './search-filters.module.css';

const order = ['Easy', 'Medium', 'Somewhat Challenging', 'Hard', 'Very Hard'];

type PanelProps = React.PropsWithChildren<{
  header: string;
}>;

function Panel({ header, children }: PanelProps) {
  return (
    <div className="ais-Panel">
      <div className="ais-Panel-header">
        <span>{header}</span>
      </div>
      <div className="ais-Panel-body">{children}</div>
    </div>
  );
}

const SearchFilters = ({ hidden = false }) => {
  const currentFilters = useCurrentRefinements();

  const { refine: clearRefinements } = useClearRefinements({
    excludedAttributes: ['resource_type'],
  });

  const hasSelectedResourceType = currentFilters.items.some((item) =>
    item.attribute.includes('resource_type'),
  );

  const hasSelectedQualification = currentFilters.items.some((item) =>
    item.attribute.includes('qualification'),
  );

  const difficultyOrder = useCallback((a, b) => {
    return order.indexOf(a.name) - order.indexOf(b.name);
  }, []);

  const isMobile = useIsMdMax();

  return (
    <div
      style={{
        display: hidden ? 'none' : 'block',
      }}
      aria-hidden={hidden}
    >
      <div className={styles.searchPanelFilters}>
        <Panel header="Resource Type">
          <CustomSelect attribute="resource_type" isMulti={false} />
        </Panel>

        {hasSelectedResourceType && (
          <Panel header="Qualification">
            <ManualSelect
              attribute="qualification"
              formatLabel={(label) => formatQualification(label)}
              onChange={clearRefinements}
              options={[
                'AS-Level-Edexcel',
                'AS-Level-Further-Edexcel',
                'A-Level-Edexcel',
                'A-Level-Further-Edexcel',
                'International-A-Level-(IAL)-Edexcel',
                'IGCSE-Edexcel-A-Higher',
                'AP-Calculus-AB',
              ].map((v) => {
                return {
                  value: v,
                  label: v,
                };
              })}
            />
          </Panel>
        )}
        {!isMobile && <AreaInfoModal />}
      </div>
      <div className={styles.searchPanelFilters}>
        {hasSelectedResourceType && hasSelectedQualification && (
          <>
            <Panel header="Series">
              <CustomSelect attribute="series" sortBy={['name']} formatLabel={removeHyphens} />
            </Panel>

            {hasSelectedQualification && (
              <Panel header="Paper">
                <CustomSelect attribute="paper" formatLabel={removeHyphens} sortBy={['name']} />
              </Panel>
            )}

            <Panel header="Division">
              <CustomSelect attribute="division" sortBy={['name']} />
            </Panel>

            <Panel header="Keyword">
              <CustomSelect attribute="keyword" />
            </Panel>

            <Panel header="Area">
              <CustomSelect attribute="area" sortBy={['name']} />
            </Panel>

            <Panel header="Subtopic">
              <CustomSelect attribute="subtopic" sortBy={['name']} />
            </Panel>

            <Panel header="Difficulty">
              <CustomSelect attribute="difficulty" sortBy={difficultyOrder} />
            </Panel>
          </>
        )}

        {isMobile && <AreaInfoModal />}
      </div>
    </div>
  );
};

export default SearchFilters;
